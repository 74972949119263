/**
 * @file
 * Styles for forms
 */

@import "variables";

//
// Forms
//

input, textarea, input[type="text"],
input[type="textarea"] {
  @include rhythm-borders(1px, .5);
  @include adjust-leading-to(1);
  border-color: $grey-light;
  border-width: 1px;
  @include box-sizing(border-box);
  max-width: 100%;
  min-height: 25px;
}

textarea {
  line-height: 1.25em;
}

select {
  background-color: white;
  height: 1.8rem;
}

button,
.button,
input[type="submit"] {
  background: $red-dark;
  //@include border-radius(3px);
  padding: 3px 5px;
  color: white;
  text-decoration: none;
  border: none;
  margin: 0 10px 0 0;
  &.no-margin {
    margin: 0;
  }
  &:hover {
    background: darken($red-dark, 10%);
    color: white;
    text-decoration: none;
  };

  &:active {
    color:white;
  };
}

input[type="submit"] {
  padding: 5px 8px;
  //font-family: $secondary-typeface;
  width: inherit;
}

.form-item, .form-actions {
  margin: .5em 0;
}

body {
  @media only screen and (max-width: 570px) {
    input, textarea, button, select {
      //max-width: 230px;
      max-width: 100%;
    }
    form {
      input, textarea, select {
        width: 100%;
      }
    }
  }
}

.ajax-progress {
  background-color: transparent;
  position: relative;
  height: 20px;
  width: 20px;

  .message {
      display: none;
    }

  .throbber {
    background-image: url(../../images/ajax-loader-white.gif);
    background-position: 0 0;
    height: 20px;
    width: 20px;
  }
}

.group-group2-inner{
  .ajax-progress {
    background-color: transparent;
    position: absolute;
    right: 15px;
    bottom: 30px;
    .throbber {
      background-image: url(../../images/ajax-loader-red.gif);
    }
  }
}



.view-mode-full {
  .attribute-widgets {

    label {
      font-size: 1.7em;
      font-weight: normal;
    }

    .ajax-progress {
      background-color: #eee;
      position: relative;
      right: 0;
      bottom: 0;

      .throbber {
        background-image: url(../../images/ajax-loader-grey.gif);
      }
    }
  }
}


.view-mode-search_result, .view-mode-commerce_quicklook {
  .ajax-progress {
    bottom: 10px;
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    width: 20px;

    .throbber {
      background-image: url(../../images/ajax-loader-gold.gif);
      display: block;
      margin: 0;
      padding: 0;

    }
  }

  .attribute-widgets {

    .form-item {
      padding-right: 30px;
    }

    .ajax-progress {
      float: right;
      position: relative;
      right: 0;
      bottom: 0;

      .throbber {
        background-image: url(../../images/ajax-loader-white.gif);
      }
    }
  }
}
